//  libraries
import React from 'react'

//containers
import ErrorSec from 'containers/Error'

//  components
import SEO from 'components/seo'

//  styles
import { ResetCSS } from 'assets/css/style'

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404: Not found" />
      <ResetCSS />
      <ErrorSec></ErrorSec>
    </>
  )
}

export default NotFoundPage
